.card-size{
   width: 250px;
}
.border{
    border-radius: 10px;
}
.yellowgreen{
    background-color: rgb(8, 139, 30) ;
    color: white;
}
.green{
    background-color:darkolivegreen;
    color: white;
}
.marg{
    margin-top: auto;
}
.siz{
    size: 10px;
}